import React, { useState } from 'react';
import 'materialize-css/dist/css/materialize.min.css';

///
/// GO TO LINE 52 TO ENABLE THE FORM
///

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Debug: Log the payload before sending
    console.log('Sending payload:', formData);

    const response = await fetch('https://maker.ifttt.com/trigger/tag_contactForm/json/with/key/i6QLSXNBY9qzudpKUggU5GevtusM3VgsuVyJ6Lyt8tj', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      console.log('Data sent successfully');
    } else {
      console.error('Error sending data:', response.statusText);
    }

    // Reset the form data
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    });
  };


// Correct
const [isFormDisabled, setFormDisabled] = useState(true);

  return (
    /* <img src={process.env.PUBLIC_URL + '/concept.png'} alt="Concept AI art of transforming business through automation" className="responsive-img" />*/
    <div className="container">
      <h4>Contact Us</h4>
      {/* Add an overlay div when the form is disabled */}
      {isFormDisabled && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '4px', // Match the border-radius of your form
        }}>
          <p style={{ margin: 0, fontSize: '20px', fontWeight: 'bold' }}>Under Construction</p>
          <p style={{ marginTop: '10px' }}>This form is currently unavailable.</p>
        </div>
      )}
      <form onSubmit={handleSubmit} style={{ position: 'relative' }}>
        {/* Remove the row class from here and manage the layout manually */}
        <div style={{ opacity: isFormDisabled ? 0.5 : 1 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}> {/* This container will handle the layout */}
            <div style={{ flex: '1 1 300px', marginRight: '20px' }}> {/* Adjust the basis and margin as needed */}
              <img src={process.env.PUBLIC_URL + '/concept.png'} alt="Concept" style={{ width: '100%', height: 'auto' }} />
            </div>
            <div style={{ flex: '3 1 600px' }}> {/* Rest of the space */}
              {/* Use individual rows for each field or adjust as necessary */}
              <div className="input-field">
                <input
                  placeholder="John"
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="validate"
                />
                <label htmlFor="firstName">First Name</label>
              </div>
              <div className="input-field">
                <input
                  placeholder="Doe"
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="validate"
                />
                <label htmlFor="lastName">Last Name</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                placeholder="johndoe@example.com"
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="validate"
              />
              <label htmlFor="email">Email</label>
              <span className="helper-text" data-error="Invalid email format" data-success="Valid email"></span>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <textarea
                id="message"
                className="materialize-textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <label htmlFor="message">Your Message</label>
            </div>
          </div>
          <button className="btn waves-effect waves-light" type="submit" name="action" disabled={isFormDisabled}>
            Submit<i className="material-icons right">send</i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;