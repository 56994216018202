import React, { useEffect } from 'react';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';
import './App.css';
import { Route, Link, Routes } from 'react-router-dom';
import About from './About';
import Home from './Home';
import ContactForm from './ContactForm';
import config from './config';

function App() {
    useEffect(() => {
        M.AutoInit();
        let elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    }, []);

    return (
        <div>
            <nav>
                <div className="nav-wrapper">
                <a href="/" className="brand-logo custom-mobile-class">&nbsp;The Automation Guru</a>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                    <ul className="right hide-on-med-and-down">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About the Book</Link></li>
                        <li><a href={config.buyLink} className="btn">Buy the Book</a></li>
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About the Book</Link></li>
                <li><a href={config.buyLink} className="btn">Buy the Book</a></li>
            </ul>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/ContactForm" element={<ContactForm />} />
                <Route path="*" element={<h1>Not Found</h1>} />
                {/* Other routes and components can go here */}
            </Routes>
        </div>
    );
}

export default App;