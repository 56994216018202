import PropTypes from 'prop-types';
import config from './config'; // assuming you have a config file

function BookCard({ book, buyLink, amazonLink, kindleLink, contactLink }) {
  return (
    <div className="row">
      <div className="col s12 m12">
        <div className="card blue-grey darken-1">
          <div className="card-content white-text">
            <h1 className="card-title">{book.title}</h1>
            <div className="book-section">
              <div className="book-image">
                <img src={book.image} className="book-cover" alt={`'${book.title}'`} onError={(e) => { e.target.onerror = null; e.target.src = config.defaultBookImage }} />
              </div>
              <div className="book-description">
                <h3>{book.excerptTitle}</h3>
                <p>{book.excerpt}</p>
                <h3>{book.descriptionTitle}</h3>
                <p>{book.description}</p>
                <h3>{book.realWorldCaseStudiesTitle}</h3>
                <p>{book.realWorldCaseStudies}</p>
                <h3>{book.automationInEverydayLifeTitle}</h3>
                <p>{book.automationInEverydayLife}</p>
                <h3>{book.pricingTitle}</h3>
                <p>{book.pricing}</p>
              </div>
            </div>
            <div className="card-action">
              {buyLink && <a href={buyLink} className="btn">Buy the Book</a>}
              {amazonLink && <a href={amazonLink} className="btn">Buy from Amazon</a>}
              {kindleLink && <a href={kindleLink} className="btn">Buy Kindle Version</a>}
              {contactLink && <a href={contactLink} className="btn">Contact</a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BookCard.propTypes = {
  book: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
};

export default BookCard;