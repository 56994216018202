import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import BookCard from './BookCard'; // Import the BookCard component
import config from './config';

const imagePath = process.env.PUBLIC_URL + '/author.jpg';

const About = () => {
  const aboutCard = {
    title: "About Matt Rogers",
    image: imagePath,
    excerptTitle: "About the Author",
    excerpt: "With an IT journey spanning over two decades, Matt Rogers is not just an expert—he's a devotee. To him, automation is more than just a technological advancement; it's a love affair. It's the very fabric of his professional existence. Matt believes in the boundless power of automation, not just as a tool for businesses, but as a transformative force in our daily lives—enabling us to care more for our loved ones, our interests, and even our pets.",
    descriptionTitle: "About the Book",
    description: "Imagine a world where your work resonates with efficiency, and time is no longer a constraint but an ally. Rewired: Transforming Business Through Automation is not just a book—it's Matt's heart and soul poured onto pages. Drawing from his extensive experience, Matt unravels the magic of automation across industries. He guides you, with the finesse only a true lover of automation can, through platforms like Microsoft's Power Platform, ensuring you harness its full potential.",
    realWorldCaseStudiesTitle: "Real-World Case Studies",
    realWorldCaseStudies: "Rewired isn't just theory—it's proven, practical knowledge. Dive into case studies that demonstrate tangible results achieved through robotic process automation, low code solutions, and AI. Whether it's manufacturing, healthcare, or legal offices, Matt showcases best practices with the clarity and insight of someone truly passionate.",
    automationInEverydayLifeTitle: "Automation in Everyday Life",
    automationInEverydayLife: "But Matt's vision for automation transcends the confines of businesses. With Rewired, journey into the realms of everyday life. Explore how automation, from home setups to finance apps and travel tools, can liberate your time, enabling you to cherish life's true treasures.",
    pricingTitle: "Contact",
    pricing: "For further insights, collaborations, or to embark on your own automation journey with Matt, reach out through the provided contact details or links."
  };

  return (
    <BookCard book={aboutCard}
      contactLink={config.contactLink}
    />
  );
};

export default About;