import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import BookCard from './BookCard'; // import the BookCard component
import config from './config';

const imagePath = process.env.PUBLIC_URL + '/bookimage.jpg';

function Home() {
  const book = {
    title: "Rewired: Transforming Business Through Automation",
    excerptTitle: "Excerpt",
    image: imagePath,
    excerpt: "Revolutionize your work and reclaim your time by navigating the automation frontier.",
    descriptionTitle: "Description",
    description: "'Rewired: Transforming Business Through Automation' is your gateway to the world of automation. This book leverages lessons learned in author Matt Rogers' 20-year IT career and immerses you in the transformative potential of automation across industries. It provides hands-on guidance for using automation platforms like Microsoft's Power Platform to improve efficiency.",
    realWorldCaseStudiesTitle: "Real-World Case Studies",
    realWorldCaseStudies: "Rewired explores real-world case studies showing how businesses have achieved significant results through implementing robotic process automation, low code solutions, and AI. You'll learn automation best practices across manufacturing, healthcare, legal offices, and more.",
    automationInEverydayLifeTitle: "Automation in Everyday Life",
    automationInEverydayLife: "Beyond business applications, Rewired dives into how automation is changing everyday life. Discover how home automation, self-driving cars, and AI-powered personal assistants are reshaping the world around us.",
    pricingTitle: "Pricing",
    pricing: "The book is available for $25 from this website. It's also available on Amazon for $33.98 for the physical copy and $7.99 for the Kindle version.",
    buyLink: config.buyLink,
    amazonLink: config.amazonLink,
    kindleLink: config.kindleLink
  };

  return (
    <BookCard book={book}
      buyLink={config.buyLink}
      amazonLink={config.amazonLink}
      kindleLink={config.kindleLink}
    />
  );
}
export default Home;


